import { Link } from 'react-scroll';
import React from 'react';
import styled from 'styled-components';

import InternalLink from './InternalLink';

const SmallInternalLink = styled(InternalLink)`
  padding: 1rem 1.5rem;
  border: none;
  color: white;
  font-size: 1.2rem;
  &:hover {
    color: grey;
    border-color: black;
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
  padding: 2rem 0;
  z-index: 1;
  @media all and (max-width: 700px) {
    flex-direction: column;
    overflow: hidden;
    justify-content: flex-start;
  }
  @media all and (min-width: 701px) {
    position: sticky;
    background: black;
    top: 4rem;
  }
`;

export default props => (
  <Wrapper>
    <Link to="story" smooth={true} offset={-150}>
      <SmallInternalLink small>Our Story</SmallInternalLink>
    </Link>
    <Link to="team" smooth={true} offset={-150}>
      <SmallInternalLink small>Team Interview</SmallInternalLink>
    </Link>
    <Link to="publications" smooth={true} offset={-180}>
      <SmallInternalLink small>Publications</SmallInternalLink>
    </Link>
    <Link to="clients" smooth={true} offset={-180}>
      <SmallInternalLink small>Clients</SmallInternalLink>
    </Link>
  </Wrapper>
);
