import { Element } from 'react-scroll';
import { Grid } from '@material-ui/core';
import React from 'react';

import AboutMenu from '../AboutMenu';
import Clients from '../Clients';
import Content from '../Content';
import Layout from '../Layout';
import Narrow from '../Narrow';
import PageTitle from '../PageTitle';
import Publications from '../Publications';

export default ({ pageContext: { about, searchIndex } }) => {
  const {
    acf: { title, content, publications, clients_new },
  } = about;
  return (
    <>
      <Layout background={true} searchIndex={searchIndex}>
        <PageTitle>{title}</PageTitle>
        <AboutMenu />
        <Element name="story">
          <div style={{ height: '1px' }} />;
        </Element>
        <Content content={content} />
        <Narrow>
          <Grid container spacing={40}>
            <Grid item xs={12}>
              <Publications publications={publications} />
            </Grid>
          </Grid>
        </Narrow>
        <Clients clients={clients_new} />
      </Layout>
    </>
  );
};
