import { Element } from 'react-scroll';
import { Grid } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import Subheading from './Subheading';
import WPImage from './WPImage';

const Wrapper = styled.div`
  padding: 2rem;
  @media all and (max-width: 700px) {
    padding: 1.5rem;
  }
`;

export default ({ clients }) => (
  <Element name="clients">
    <Subheading>Selected Clients</Subheading>
    <Grid container justify="center" alignItems="center" spacing={24}>
      {clients.map(client => (
        <Grid key={client.id} item xs={3} sm={2} md={2}>
          <Wrapper>
            <WPImage src={client.url || ''} />
          </Wrapper>
        </Grid>
      ))}
    </Grid>
  </Element>
);
