import { Element } from 'react-scroll';
import React from 'react';
import styled from 'styled-components';

import Subheading from './Subheading';

const Title = styled.h3`
  color: white;
  font-size: 1.2rem;
  margin-top: 3.5rem;
  margin-bottom: 0.5rem;
  font-family: ffvCorporateFat, sans-serif;
`;
const Prices = styled.div`
  color: white;
  font-size: 1.2rem;
  font-family: ffvGaramond, serif;
`;
const Publication = ({ publication, projects }) => (
  <>
    <Title>{publication}</Title>
    <Prices dangerouslySetInnerHTML={{ __html: projects }} />
  </>
);
export default ({ publications }) => (
  <Element name="publications">
    <Subheading>Selected Publications</Subheading>
    {publications &&
      publications.map(publication => (
        <Publication
          key={publication.title_of_publication}
          publication={publication.title_of_publication}
          projects={publication.project__article__etc}
        />
      ))}
  </Element>
);
